<template>
    <v-container fill-height fluid>
        <v-card
        v-if="!api.isSuccesful"
        class="d-flex justify-center mx-auto white rounded-lg"
        elevation-1
        min-width="400px"
        min-height="400px"
        outlined>
        <v-form
        v-if="this.userFound"
        class="my-auto">
        <div
        class="d-flex justify-center">
            <v-icon
            x-large
            color="primary">
                mdi-account-key
            </v-icon>
        </div>
        <div
        class="d-flex justify-center text-h3 my-3">
            Reset Password
        </div>
        <div
        class="d-flex justify-center my-3 mx-auto">
            {{ form.email }}
        </div>
            <hr/>
            <AError :api="this.api"
                class="my-2"/>
            <v-text-field
            v-model="form.password"
            :append-icon="showPassword? 'mdi-eye' :'mdi-eye-off'"
            :type="showPassword ? 'text':'password'"
            class="mx-4 mt-6"
            dense
            outlined
            label="password"
            required
            @click:append="showPassword = !showPassword">
            </v-text-field>
            <v-text-field
            v-model="confirmPassword"
            :append-icon="showConfirmPassword?'mdi-eye':'mdi-eye-off'"
            :type="showConfirmPassword?'text':'password'"
            class="mx-4 "
            dense
            outlined
            required
            label="confirm password"
            @click:append="showConfirmPassword = !showConfirmPassword">
            </v-text-field>
            <div
            class="d-flex justify-center mx-auto mb-4">
                <v-btn
                :loading="this.api.isLoading"
                color="primary"
                @click="updatePassword()">
                Confirm
                </v-btn>
                <v-btn
                color="primary"
                plain
                class="mx-2">
                Clear
                </v-btn>
            </div>
        </v-form>
    </v-card>
        <!-- <v-dialog
        v-model="api.isSuccesful"
        width="auto"
        height="auto"> -->
        <v-card
            v-if="api.isSuccesful"
            class="mx-auto">
            <v-img
            class="ma-7"
            src="/images/taxpod_vision_logo.png"
            width="auto"
            ></v-img>
            <hr/>
            <div
            class="d-flex justify-center">
                <v-icon
                size="100"
                color="green">
                mdi-check-circle
                </v-icon>
            </div>
            <v-card-title
            class="d-flex justify-center text-h4 mb-2">
            Congratulations
            <v-icon
            color="primary">
                mdi-creation
            </v-icon>
            </v-card-title>
            <v-card-subtitle
            class="d-flex justify-center">
            Succesfully setting up your Password<br/>
            </v-card-subtitle>
            <div
            class="d-flex justify-center font-weight-bold">
            Enjoy your VISION
            </div>
            <v-card-actions
            class="d-flex justify-center">
                <v-btn
                color="primary"
                href="/home">
                Go to home page
                </v-btn>
            </v-card-actions>
        </v-card>
        <!-- </v-dialog> -->
    </v-container>
</template>

<script>
export default{
    components:{

    },
    props:[

    ],
    data:()=>({
        form :{
            email:null,
            password:"",
        },
        errorMessage:"",
        userFound:false,
        showPassword:false,
        showConfirmPassword:false,
        token:null,
        confirmPassword:"",
        api : {
            isLoading : false,
            isError : false,
            isSuccesful:false,
            error : null,
            url : null,
        }
        
    }),
    created(){
        this.api.method = "get",
        this.api.url = process.env.VUE_APP_SERVER_API+"/user/token/";
        this.api.callbackReset=()=>{
            // this.api.isLoading = true;
            // this.api.isError = false;
        };
        this.api.callbackError = (e) =>
        {
           
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp)=>
        {
            if(resp.status == "success"){
                if(this.api.url ==  process.env.VUE_APP_SERVER_API+"/user/token/"+this.$route.params.token )
                {
                    this.form.email = resp.data[0].email;
                    this.userFound = true;
                }
                if(this.api.url == process.env.VUE_APP_SERVER_API+"/user/password")
                {
                    this.api.isSuccesful = true;
                }
            }   
        }
    },
    mounted(){
        this.fetchUserDetail()
    },
    methods:{
        fetchUserDetail(){
            this.api.url = process.env.VUE_APP_SERVER_API+"/user/token/"+this.$route.params.token;
            this.$api.fetch(this.api);
        },
        validateInput(){
            let isValidated = true;
            let password = this.$_.clone(this.form.password);
            let confirmPassword = this.$_.clone(this.confirmPassword);
            if(password==="" || confirmPassword===""){
                isValidated = false;
                this.errorMessage = "Invalid input"
            }
            else if(password !== confirmPassword){
                isValidated = false;
                this.errorMessage = "Please make sure password and confirm password is matched"
            }
            return isValidated;
        },
        updatePassword(){
            if(!this.validateInput()){
                this.api.isError = true;
                this.api.error = this.errorMessage;
                return 1;
            }
            this.api.isLoading = true;
            this.api.method = "put"
            this.api.url = process.env.VUE_APP_SERVER_API+"/user/password";
            this.api.params = this.$_.clone(this.form);
            this.$api.fetch(this.api);
        }
    },
}
</script>